
import './style.scss';
import {Col, Row} from "react-bootstrap";
import moment from "moment";
import {PopUpAlert} from "../../../controller/utils";

export default function Order({order}){

    const shipping = order.utilityRequest && order.utilityRequest.length > 0 ? order.utilityRequest[0] : {};

    const address = shipping && shipping.dropOffAddress ? shipping.dropOffAddress : {};


    return (
        <div className={'orderRow p-4 mb-4'}>
            <Row>
                <Col className={'text-white'}>
                    ORDER #{order.id} - Placed On {moment(order.createdAt).format('ddd DD MMMM YYYY h:mm:ss A')}<br/><br/>
                </Col>
            </Row>
            <Row>
                <Col className={'text-white'} md={5}>
                    <h5><br/>Shipping By {shipping?.shippingOption?.description}</h5>
                    <p>
                        {address.firstName} {address.lastName}<br/>
                        {address.address1}<br/>
                        {address.address2}, {address.countryName}
                    </p>
                </Col>
                <Col md={5} className={'row'}>
                    {order.orderItems.map((oi) => {
                        return <Col xs={6}>
                            <p className={'text-white'}>{oi.asset.name}</p>
                            <img src={oi?.asset.mediaPreviewUrl} className={'img-fluid orderImg'}/>
                        </Col>
                    })}

                </Col>

                <Col className={'text-white text-end'}>
                    <h6>Total</h6>
                    <h3>${order.totalAmount + parseFloat((shipping?.shippingOption?.usdRate?.toFixed(2)))}</h3>
                    <p>&nbsp;</p>
                    <h6>Shipping status</h6>
                    <h4>{shipping.status}</h4>
                </Col>
            </Row>
        </div>
    )

}
