import { useEffect } from "react";
import ProfileCard from "./ProfileCard";
import styles from "./viewprofile.module.sass"
import { CircularProgress } from "@material-ui/core";
const ProfileCardArea = ({ ownedAsset, createdNfts, currentTab, checkIfEmpty, createdLoader, profileDetails }) => {
    useEffect(() => {
        console.log(ownedAsset)
        console.log('173' ,createdNfts, currentTab)

    }, [currentTab])

    return (

        <>
            <div className={styles.cardArea}>
                {/*{*/}
                {/*    currentTab == 0 &&*/}

                {/*    <>*/}
                {/*     {createdLoader &&*/}
                {/*            <div className={styles.loaderContent} style={{ height: 200 }}>*/}
                {/*                <CircularProgress style={{color: "#FFF"}} />*/}
                {/*            </div>*/}
                {/*        }*/}
                {/*        {!createdLoader && checkIfEmpty(ownedAsset) ?*/}
                {/*            <>*/}
                {/*                <div className={styles.msgCenter}>*/}
                {/*                    <p>No Assets Found</p>*/}
                {/*                </div>*/}
                {/*            </>*/}

                {/*            :*/}
                {/*            <>*/}
                {/*                {*/}
                {/*                    ownedAsset.map((item, index) => (*/}
                {/*                        <>*/}
                {/*                            <div className={styles.cardContainer}>*/}
                {/*                                <ProfileCard details={item} currentTab={currentTab} profileDetails={profileDetails} />*/}
                {/*                            </div>*/}
                {/*                        </>*/}

                {/*                    ))*/}
                {/*                }*/}
                {/*            </>*/}
                {/*        }*/}


                {/*    </>*/}
                {/*}*/}

                <>
                        {createdLoader &&
                            <div className={styles.loaderContent} style={{ height: 200 }}>
                                <CircularProgress style={{color: "#FFF"}} />
                            </div>
                        }

                        {
                            !createdLoader &&
                            <>

                                {
                                    checkIfEmpty(createdNfts) ?
                                        <>
                                            <div className={styles.msgCenter} >
                                                <p>No Assets Found</p>
                                            </div>
                                        </>

                                        :
                                        <>
                                            {
                                                createdNfts.map((item, index) => (
                                                    <>
                                                        <div className={styles.cardContainer}>
                                                            <ProfileCard
                                                                details={item}
                                                                profileDetails={profileDetails}
                                                                currentTab={currentTab}
                                                            />
                                                        </div>
                                                    </>

                                                ))
                                            }

                                        </>
                                }
                            </>
                        }



                    </>




            </div>






        </>
    );
}

export default ProfileCardArea;
