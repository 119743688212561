import WertWidget from "@wert-io/widget-initializer";
import {signSmartContractData} from "@wert-io/widget-sc-signer";
import {buyWithUSDC, getConfig, getWeb3Provider, PopUpAlert} from "../../controller/utils";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {wertOtherWidgetOptions} from "../../utils/config";
import {contractDetails} from "../../utils/contract";
import Web3 from "web3";

export default function WertCheckoutWidget({orderId, wertClickId, receiver, tokenIds, finalAmount, signaturePayload, onPaymentStatusChange, onClose}) {

    const config = getConfig();

    const profile = useSelector((state) => state?.profileReducer);

    const [paymentData, setPaymentData] = useState({});

    const SERVER_ENVIORNMENT = process.env.REACT_APP_ENVIRONMENT_MODE;

    const initWert = async (userToken) => {
        console.log('Initiating wert...');
        const privateKey = process.env.REACT_APP_WERT_KEY;

        // let [YEAR, MONTH, DATE] = profile.dob.split("-");

        let wertProfileDetails = {
            // full_name: profile.firstName + " " + profile?.lastName,
            // email: profile?.email,
            // date_of_birth:
            //     profile?.country === "US"
            //         ? `${MONTH}/${DATE}/${YEAR}`
            //         : `${DATE}/${MONTH}/${YEAR}`,
            // phone: "+" + profile?.mobileNumber,
            // country_of_residence: profile.country,
        };


        const web3 = await getWeb3Provider();

        const inputData = [
            orderId,
            receiver,
            tokenIds,
            finalAmount,
            signaturePayload.metadata,
            signaturePayload.creators,
            signaturePayload.result
        ];

        const scInputData = web3.eth.abi.encodeFunctionCall(
            buyWithUSDC, inputData
        );

        const signedData = signSmartContractData(
            {
                address: receiver, // / Change needed - Take receiver address of user // userprofile
                commodity: ["PRODUCTION"].includes(SERVER_ENVIORNMENT) ? "USDCc" : "TT", // Currently Test token , In prod it will change to USDC
                commodity_amount: Web3.utils.fromWei(finalAmount, "mwei"),
                network: ["PRODUCTION"].includes(SERVER_ENVIORNMENT)? "polygon" : "bsc",
                sc_address: contractDetails?.ASLY_MARKETPLACE_ADDRESS, // your SC address - take from contract abi - smart contract address
                sc_input_data: scInputData
            },
            privateKey
        );

        const cartItems = {
            extra: {
                item_info: {
                    // author: `${transactionDetails?.creator?.firstName} ${transactionDetails?.creator?.lastName}`,
                    image_url: 'https://lii.market/images/lii-short-logo.png',
                    name: 'Lii Checkout',
                    seller: "lii",
                },
            },
        };

        wertOtherWidgetOptions.click_id = wertClickId;

        const wertWidget = new WertWidget({
            ...signedData,
            ...wertOtherWidgetOptions,
            ...wertProfileDetails,
            ...cartItems,

            listeners: {
                "payment-status": (data) => {
                    console.log("wert payment status");
                    setPaymentData(data);
                    if(data.status === 'success'){
                        onPaymentStatusChange(data);
                        const removeIframeByMatchingUrl = (url) => {
                            const iframes = document.querySelectorAll("iframe");

                            for (const iframe of iframes) {
                                if (iframe.src.includes(url)) {
                                    iframe.remove();
                                }
                            }
                        };
                        removeIframeByMatchingUrl("sandbox.wert.io");
                        wertWidget.destroy();
                    }
                },
                close: (data) => {
                    console.log("wert is closed. check data below");
                    console.log(data);
                    onClose(paymentData);
                },
            },
        });
        wertWidget.mount();
    }
    useEffect(() => {
        if (config) {
            initWert(config.headers.Authorization);
        }
    }, []);

    return <div id="widget"></div>
}
