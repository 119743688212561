import {Button, Col, Container, Row, Spinner} from "react-bootstrap";
import {useEffect, useState} from "react";
import UserAddresses from "../../components/UserAddresses";
import {axiosInstance} from "../../utils/API";

import "./style.scss";
import {useSelector} from "react-redux";
import {getConfig, PopUpAlert} from "../../controller/utils";
import Web3 from "web3";
import WertCheckoutWidget from "../../components/WertChekcoutWidget";
import Loader from "../Item/Loader";
import { v4 as uuidv4 } from "uuid";
import {useHistory} from "react-router";

export default function CartCheckout() {

    const walletId = useSelector((state) => state?.counter?.user?.account?.[0]);

    const [wert, setWert] = useState({
        active: false
    });

    const history = useHistory();

    const [paymentInProgress, progressPayment] = useState(false);
    const [progress, setProgress] = useState({title: '', content: ''});
    const [orderSucceeded, setOrderSucceeded] = useState(false);
    const [paymentInitiated, setPaymentInitiated] = useState(false);

    const [loading, isLoading] = useState(false);
    const [paying, isPaying] = useState(false);
    const [cartItemsCount, setCartItemsCount] = useState(0);
    const [cartItems, setCartItems] = useState([]);
    const [tokenIds, setTokenIds] = useState([]);
    const [chosenAddress, setChosenAddress] = useState(null);
    const [shipmentOptions, setShipmentOptions] = useState([]);
    const [shipmentOption, setShipmentOption] = useState(null);

    const [cartOrderId, setCardOrderId] = useState(null);
    const [wertClickId, setWertClickId] = useState(null);

    const [signaturePayload, setSignaturePayload] = useState({});


    useEffect(() => {
        document.getElementById('cartHeaderCount').style.display = 'none';
        // document.getElementById('customHeader').style.display = 'none';
        // document.getElementById('drawerHeaderWrapper').style.display = 'none';
        // document.getElementById('logoHyper').style.margin = '0 auto';
    });

    const getShipmentOptions = async () => {
        isLoading(true);
        setShipmentOptions([]);
        setShipmentOption(null);
        let config = {
            headers: {
                Authorization: localStorage.getItem("userToken"),
            },

        };
        return await axiosInstance
            .post("/user/cart/claimUtility", {addressId: chosenAddress.id}, config)
            .catch((err) => {
                console.log(err);
                isLoading(false);
            });
    }

    const removeItemFromCart = async (assetId) => {
        let config = {
            headers: {
                Authorization: localStorage.getItem("userToken"),
            },
            data: {
                assetId: assetId,
            },
        };
        return await axiosInstance
            .delete("/user/cart/removeFromCart", config)
            .catch((err) => {
                console.log(err);
            });
    }

    const updateCartItem = async (item, newQuantity) => {

        let config = {
            headers: {
                Authorization: localStorage.getItem("userToken"),
            },
        };
        return await axiosInstance
            .patch(`/user/cart/updateCartItem/${item.id}`, {quantity: newQuantity}, config)
            .catch((err) => {
                console.log(err);
            });

    }

    const getCartItems = async () => {
        let config = {
            headers: {
                Authorization: localStorage.getItem("userToken"),
            },
        };
        return await axiosInstance
            .get("/user/cart/viewAll", config)
            .catch((err) => {
                console.log(err);
            });
    }

    const getAndSetCartItems = () => {
        getCartItems().then((r) => {
            if(r && r.data){
                setCartItemsCount(r?.data?.result?.cartItems?.count);
                setCartItems(r?.data?.result?.cartItems?.rows);
            }else {
                setCartItemsCount(0);
                setCartItems([]);
                window.location = '/explore'
            }
        });
    }

    useEffect(() => {
        getAndSetCartItems();
    }, [])

    useEffect(() => {
        if (cartItems === undefined) {
            setCartItems([])
            setCartItemsCount(0);
        } else if (cartItems.length > 0) {
            cartItems.forEach((item) => {
                tokenIds.push(item.asset.tokenId);
            });
            setTokenIds(tokenIds);
        }
    }, [cartItems]);

    useEffect(() => {
        if (chosenAddress) {
            resetAndGetShipmentOptions();
        }

    }, [chosenAddress]);

    const resetAndGetShipmentOptions = () => {
        setShipmentOption(null);
        getShipmentOptions().then((r) => {
            setShipmentOptions(r.data?.result?.shipmentCarrierOptions);
            isLoading(false);
        })
    }

    useEffect(() => {
        if (shipmentOptions.length > 0) {
            setShipmentOption(shipmentOptions[0])
        }
    }, [shipmentOptions])

    let cartSubTotalAmount = 0;
    let shippingAmount = shipmentOption ? parseFloat((shipmentOption.Rate / shipmentOption.WorkingCurrency.Rate).toFixed(2)) : 0;

    const getTotalAmount = () => {
        return parseFloat(cartSubTotalAmount + shippingAmount).toFixed(2)
    }


    const getSignature = async (config) => {
        const sigPayload = {
            collectableId: tokenIds,
            payer: walletId,
            amount: parseInt(Web3.utils.toWei(getTotalAmount().toString(), "mwei")),
            isCard: true,
            usdRate: shipmentOption.Rate/shipmentOption.WorkingCurrency.Rate
        }

        return await axiosInstance
            .post("/asset/cart/getSignature", sigPayload, config);
    }

    const checkoutOrder = async (config) => {

        const clickId = uuidv4();
        setWertClickId(clickId);

        const checkoutPayload = {
            shippingOptions: {
                carrier: shipmentOption.ShippingRateComputationMethodSystemName,
                description: shipmentOption.MethodName,
                id: chosenAddress.id,
                homeRate: shipmentOption.Rate,
                usdRate: shipmentOption.Rate / shipmentOption.WorkingCurrency.Rate
            },
            click_id: clickId,
            checkoutAssetData: cartItems.map((item) => {
                return {
                    "assetId": item.asset.id,
                    "assetUtilityId": item.assetUtilityId,
                    "salePrice": item.asset.salePrice,
                    "saleType": "fixed",
                    "status": "pending",
                    "quantity": item.quantity,
                    "paymentType": "metamask",
                    "from": walletId,
                    "to": "0x612c809349a6c84336b7c94f77f81e680610c6e0",
                    "ethPrice": 2.824060999717594,
                    "paymentDetail": null
                }
            })
        }

        return await axiosInstance
            .post("/asset/cart/checkout", checkoutPayload, config);
    }

    const confirmOrder =  async (tx, cartOrderId, success) => {
        const config = getConfig();

        const confirmPayload = {
            cartOrderId: cartOrderId,
            txHash: tx,
            success: success
        }

        return await axiosInstance
            .post("/cart/transaction/confirmation/callback", confirmPayload, config);
    }

    const payWithCard = async () => {
        const config = getConfig();



        isPaying(true);

        progressPayment(true);
        setProgress({
            title: 'Validating your items...',
            content: ''
        });

        getSignature(config).then(sR => {
            setSignaturePayload(sR.data.result);

            setProgress({
                title: 'Items Validated',
                content: 'Creating your order...'
            });

            checkoutOrder(config).then(cR => {

                if (cR.data.success) {

                    setProgress({
                        title: 'Order Created',
                        content: 'Connecting with wert...'
                    });

                    setCardOrderId(cR.data?.result?.orderSaved.cartOrderId);
                    setWert({active: true})

                } else {
                    PopUpAlert(
                        "Oops",
                        cR?.data?.message,
                        "error"
                    );
                    progressPayment(false);
                }
            }).catch(err => {
                if (err.response) {
                    PopUpAlert(
                        "Oops",
                        err.response.data.message,
                        "error"
                    );
                }
                isLoading(false);
                isPaying(false);
                progressPayment(false);
            });
        }).catch((err) => {
            console.log(err);
            isLoading(false);
            isPaying(false);
        });
    }

    const payButtonContent = () => {
        return paying ? (
            <span><Spinner size={'sm'} animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner> paying ${getTotalAmount()}... </span>
        ) : (
            <span>Pay ${getTotalAmount()}
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"><path
                    d="M22.7578 10V20C22.7578 20.2652 22.6525 20.5196 22.4649 20.7071C22.2774 20.8946 22.023 21 21.7578 21H3.75781C3.4926 21 3.23824 20.8946 3.05071 20.7071C2.86317 20.5196 2.75781 20.2652 2.75781 20V10H22.7578ZM22.7578 8H2.75781V4C2.75781 3.73478 2.86317 3.48043 3.05071 3.29289C3.23824 3.10536 3.4926 3 3.75781 3H21.7578C22.023 3 22.2774 3.10536 22.4649 3.29289C22.6525 3.48043 22.7578 3.73478 22.7578 4V8ZM15.7578 16V18H19.7578V16H15.7578Z"
                    fill="#F2F6F9"/></svg></span>
        )
    }

    return (
        <Container>
            <Row>
                <h1 className={'text-white my-5 text-start'}>Secure Checkout</h1>
            </Row>
            <Row className={'position-relative'}>
                {paying && <div id={'payingLoader'}></div>}
                <Col md={8}>
                    <div className={'checkoutBox'}>
                        <UserAddresses headerTitle={'Delivery To'} onAddressSelected={(address) => {
                            setChosenAddress(address)
                        }} chosenAddress={chosenAddress}/>
                    </div>

                    <div className={'checkoutBox'}>
                        <h4 className={'text-white'}>Select Courier</h4>
                        <div className={'text-white my-2'}>
                            {!loading && shipmentOptions.length === 0 &&
                                <h6 className={'text-white'}><i>No Options Available</i></h6>}
                            {loading && <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>}
                            {shipmentOptions.map((sh) => {
                                return <div className={'d-flex flex-row'}>
                                    <input checked={shipmentOption === sh} onClick={() => setShipmentOption(sh)}
                                           value={shipmentOption} type={'radio'} name={'shipmentOption'}/>
                                    <div className={'mx-2'}>
                                        <h5> {sh.Name} - <small>${(sh.Rate / sh.WorkingCurrency.Rate).toFixed(2)}</small>
                                        </h5>
                                        <p><i>{sh.MethodName}</i></p>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </Col>
                <Col md={4}>
                    <div className={'checkoutBox'}>
                        <h4 className={'text-white'}>Items</h4>
                        {cartItems.length === 0 && <h6 className={'text-white'}><i>No Items</i></h6>}
                        {cartItems.map((cartItem) => {

                            cartSubTotalAmount += (cartItem.asset.regularPrice * cartItem.quantity);

                            return <div className={'d-flex flex-row my-2 overflow-hidden w-100'}>
                                <div className={'me-3'} style={{
                                    width: '30%',
                                    height: '90px',
                                }}>
                                    <img src={cartItem.asset.ipfsImageUrl} style={{
                                        objectFit: 'cover',
                                        width: '100%',
                                        height: '100%'
                                    }}/>
                                </div>
                                <div className={'text-white w-100 pe-3'}>
                                    <div className={'d-flex flex-row justify-content-between'}>
                                        <h6>{cartItem.asset.name}</h6>
                                        <img src={'/Icons/trash.svg'} style={{
                                            cursor: 'pointer'
                                        }
                                        } onClick={() => {
                                            removeItemFromCart(cartItem.asset.id).then((r) => {
                                                getAndSetCartItems();
                                            })
                                        }}/>
                                    </div>
                                    <p className={'my-1'}>
                                        Quantity:
                                        <b><Button className={'cartCountControl'} onClick={() => {
                                            if(cartItem.quantity == 1){
                                                removeItemFromCart(cartItem.asset.id).then((r) => {
                                                    getAndSetCartItems();
                                                    resetAndGetShipmentOptions();
                                                })
                                            }else{
                                                updateCartItem(cartItem, cartItem.quantity-1).then((resp)=>{
                                                    getAndSetCartItems();
                                                    resetAndGetShipmentOptions();
                                                })
                                            }
                                        }
                                        }>-</Button>{cartItem.quantity}<Button className={'cartCountControl'} onClick={() => {
                                            updateCartItem(cartItem,cartItem.quantity+1).then((resp)=>{
                                                getAndSetCartItems();
                                                resetAndGetShipmentOptions();
                                            });}
                                        }>+</Button></b>
                                    </p>
                                    <p className={'my-1'}><h5>${cartItem.asset.regularPrice}</h5></p>
                                </div>
                            </div>
                        })}

                        <div className={'w-100 text-center mt-5'}>

                            {cartItems.length > 0 && <div className={'cartTotalsWrapper'}>
                                <div><span>Sub Total</span> <span>${cartSubTotalAmount}</span></div>
                                <div><span>Shipping</span> <span>${shippingAmount}</span></div>
                                <div><span>Total</span> <span>${getTotalAmount()}</span></div>
                            </div>}

                            <button id={'cartBuyButton'} onClick={payWithCard}
                                    disabled={!shipmentOption || getTotalAmount() <= 0}>
                                {payButtonContent()}
                            </button>
                        </div>
                    </div>

                    {paymentInProgress &&
                        <Loader
                            active={true}
                            customize={true}
                            title={progress.title}
                            content={progress.content}
                            backGround="/images/liiLogoArabic.png"
                        />
                    }

                    {wert.active && <WertCheckoutWidget
                        orderId={cartOrderId}
                        wertClickId={wertClickId}
                        receiver={walletId}
                        tokenIds={tokenIds}
                        finalAmount={signaturePayload.payload.price}
                        signaturePayload={signaturePayload}
                        onPaymentStatusChange={(d) => {
                            if(d.status === 'success'){
                                setOrderSucceeded(true);
                                setWert({active: false});
                                confirmOrder(d.tx_id, cartOrderId, true).then((r) =>{
                                    progressPayment(false);
                                    PopUpAlert(
                                        "Payment Successful!",
                                        'We have received your payment, your order will be processed soon',
                                        "success"
                                    ).then(() => {
                                        history.push("/dashboard");
                                    });
                                });
                            }
                        }}
                        onClose={(paymentData) => {
                            isPaying(false);
                            progressPayment(false);
                            if(paymentData && paymentData.status === 'pending'){
                                PopUpAlert(
                                    "Wert Closed!",
                                    'It seems you have made a payment but still not completed! Keep an eye on the orders tab under your profile',
                                    "warning"
                                ).then(() => {
                                    history.push("/dashboard");
                                });
                            }else{
                                PopUpAlert(
                                    "Wert Closed!",
                                    'You have closed wert popup before initiating any payment.',
                                    "warning"
                                );
                            }
                        }}
                    />}

                    {/*{wert?.active == true ? (*/}
                    {/*    <>*/}
                    {/*        <div id="wertWidgetDiv">*/}
                    {/*            {!closeWert && (*/}
                    {/*                <WertWidgetDiv*/}
                    {/*                    arg={{*/}
                    {/*                        productDetails: productDetails,*/}
                    {/*                        mode: wert?.mode,*/}
                    {/*                        setOpenWertModal: setWert,*/}
                    {/*                        saleType: "fixed",*/}
                    {/*                        bidAmountFiat: wert?.bidAmountFiat,*/}
                    {/*                        bidAmountCrypto: wert?.bidAmountCrypto,*/}
                    {/*                        handleWertCheckOut: handleWertCheckOut,*/}
                    {/*                        setWertLoaded: setWertLoaded,*/}
                    {/*                        handleWertResult: handleWertResult,*/}
                    {/*                        processWertResult: handleWertCheckOut,*/}
                    {/*                        onCloseWidget: onCloseWidget,*/}
                    {/*                        closeWert: closeWert,*/}
                    {/*                        setCloseWert: setCloseWert,*/}
                    {/*                        callSetSuccessShow,*/}
                    {/*                        updateFixedOrderStatus,*/}
                    {/*                    }}*/}
                    {/*                    shipmentOption={shipmentOption}*/}
                    {/*                />*/}
                    {/*            )}*/}
                    {/*        </div>*/}
                    {/*    </>*/}
                    {/*) : null}*/}


                </Col>
            </Row>


        </Container>

    )
}
