import { useHistory } from "react-router"
import styles from "./SignInModal.module.sass"
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { magic } from "../../../controller/utils";
import { setMagicUser } from "../../../redux/counterSlice";
import { CircularProgress } from "@material-ui/core";
import EmailForm from "../../../screens/Email";
function SignInModal({ handleConnectWallet, hideLoginModal }) {
    const history = useHistory();

    const [loader, setLoader] = useState(false);

    const ua = navigator.userAgent || navigator.vendor || window.opera;
    const isInstagram = (ua.indexOf('Instagram') > -1) ? true : false;

    const loginTypes = [
        {
            text: "Login With Google",
            provider: "google",
            image: "/images/google.svg"
        },
        // {
        //     text: "Login With Twitter",
        //     provider: "twitter",
        //     image: "/images/xicon.png"
        // },
        // {
        //     text: "Login With Microsoft",
        //     provider: "microsoft",
        //     image: "/images/microsoft.png"
        // },
        {
            text: "Login With Apple",
            provider: "apple",
            image: "/images/apple.png"
        }
    ];

    //Remove login with google if its Instagram browser.
    if(isInstagram)
        loginTypes.splice(0, 1);



    const handleLogin = async (type) => {
        if (type == "email") {
            history.push("/magiclogin");
        }
        else {
            hideLoginModal()
            await handleConnectWallet()

        }
    }

    const magicUser = useSelector((state) => state.counter.magicUser);
    const dispatch = useDispatch()
    useEffect(() => {
        if (!magicUser) {
            magic.user.logout().then((res) => {
                dispatch(setMagicUser(null))
            }
            )
        }
    }, [])
    const handleSocialMediaLogin = async (type) => {
        setLoader(true)
        const didToken = await magic.oauth.loginWithRedirect({
            provider: type,
            redirectURI: `${window.location.origin}/`

        });
    }

    return (
        <div className={styles.modal}>
            <div className={styles.signinModalWrapper}>
            <div className={styles.modal_content}>
                {loader &&
                    <div className={styles.loaderPad}>
                        <div className={styles.loader}>
                            <CircularProgress style={{color: "#FFF"}} />
                        </div>
                    </div>

                }
                <div className={styles.close} onClick={() => {
                    hideLoginModal();
                }}>
                    <img src="/images/close-line.svg" />
                </div>

                <div className={styles.info}>
                    <img src="/images/robot.png" className={'img-fluid'}/>
                    <h3>Log in by entering your email and using the OTP
                        we send you. </h3>
                </div>
                <div className={styles.signInOptions}>
                    <div className={styles.option}>
                        <EmailForm />
                    </div>
                    {/*<div className={styles.option} onClick={() => handleLogin("wallet")}>*/}
                    {/*    <button className={styles.walletOptions}>*/}
                    {/*        Connect your wallet <img src="/Icons/wallet.svg" />*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </div>
                <div>
                    <p className={styles.or}>Or join Lii with</p>
                </div>
                <div className={styles.socialLogin}>
                    {
                        loginTypes.map((item, index) => (
                            <div className={styles.logo} onClick={() => {
                                handleSocialMediaLogin(item.provider)
                            }}>
                                <img src={item.image} loading="lazy" />
                            </div>
                        ))
                    }

                </div>
            </div>
            </div>

        </div>
    );
}

export default SignInModal;
