import styles from "./dashcardview.module.sass";
import {axiosInstance} from "../../utils/API";
import {useEffect, useState, useLayoutEffect} from "react";
import Card from "../../components/Card";
import cn from "classnames";
import {useSelector} from "react-redux";
import queryString from "query-string";
import {useHistory} from "react-router";
import {useLocation} from "react-router-dom";
import ReactPaginate from "react-paginate";
import {CircularProgress} from "@material-ui/core";
import Order from "../Item/Order";
import {Col, Container, Row} from "react-bootstrap";


function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth]);
        }

        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
}

const DashCardView = ({id, page, setPage}) => {
    const [data, setData] = useState(null);
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const history = useHistory();
    const {search} = useLocation();
    const [screenWidth] = useWindowSize();
    const [auctionParams, setAuctionParams] = useState({
        limit: 8,
        page: 1,
        type: "won",
    });
    const euroValue = useSelector((state) => state.counter.euroValue);

    let config = {
        headers: {
            Authorization: localStorage.getItem("userToken"),
        },
    };
    //id o is for nfts
    //id 1 is for my bids
    const getNFTData = async () => {
        console.log("purchase", new Date());
        await axiosInstance
            .get(`/user/orders?limit=8&page=${page}`, config)
            .then((res) => {
                setData(res?.data?.result?.orders);
                setCount(res?.data?.result?.totalPages);
                setIsLoading(false)
                console.log(res);
            });
    };

    // https://user-api-dev.lii.market/v1/user/orders?limit=10&page=1

    useEffect(() => {
        const urlParam = queryString.parse(search);
        if (urlParam?.pn) {
            setPage(urlParam?.pn);
        }
    }, []);

    useEffect(() => {
        getNFTData();
    }, [id, page]);

    const handlePageClick = ({selected: selectedPage}) => {
        setPage(selectedPage + 1);
        setAuctionParams({...auctionParams, page: selectedPage + 1});
        history.push(`/dashboard?tab=${id}&pn=${selectedPage + 1}`);
    };
    return (
        <>
            {isLoading && (
                <div className={styles.loaderContent}>
                    <CircularProgress style={{color: "#FFF"}}/>
                </div>
            )}
            {/*<div className={styles.viewContainer}>*/}
            <Container>
                <Row>
                    <Col className={'m-0 p-0'}>
                        {data?.length > 0 ? (
                            data?.map((x, index) => (
                                <Order order={x}/>
                                // <Card
                                //   className={styles.card}
                                //   item={x}
                                //   key={index}
                                //   price={euroValue}
                                //   type={"view"}
                                //   hidePurchaseToolBar={true}
                                //   details={x}
                                //   sourcePage={"myNFT"}
                                //   // auctionTabId={auctionTabId}
                                // />
                            ))
                        ) : (

                            !isLoading && <div className={`noRecordsFound ${styles.noRecordsFound}`}>
                                <span>No records found.</span>
                            </div>
                        )}
                        {data?.length > 0 && (
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                pageCount={count}
                                onPageChange={handlePageClick}
                                forcePage={page - 1}
                                containerClassName={styles.pagination}
                                previousLinkClassName={cn(styles.pagination__link, "page_switch")}
                                // previousClassName={cn(styles.pagination__button, "page_switch")}
                                nextLinkClassName={cn(styles.pagination__link, "page_switch")}
                                pageClassName={"page_no"}
                                disabledClassName={styles.pagination__link__disabled}
                                activeClassName={styles.pagination__link__active}
                                pageRangeDisplayed={screenWidth < 640 ? 1 : 3}
                                marginPagesDisplayed={screenWidth < 640 ? 1 : 4}
                            />
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default DashCardView;
