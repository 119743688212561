import {getConfig} from "../../controller/utils";
import {axiosInstance} from "../../utils/API";
import {useEffect, useState} from "react";
import styles from "./dashcardview.module.sass";
import {CircularProgress} from "@material-ui/core";
import {Col, Container, Row} from "react-bootstrap";
import './unlockedcontent.scss';

import FsLightbox from "fslightbox-react";


export default function UnlockedContent() {

    const [data, setData] = useState(null);
    const [media, setMedia] = useState(null);
    const [mediaCategories, setMediaCategories] = useState({});
    const [lightBoxMedia, setLightBoxMedia] = useState([]);
    const [lightBoxCaptions, setLightBoxCaptions] = useState([]);
    const [curSlide, setCurSlide] = useState(0);
    const [curItem, setCurrentItem] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [mediaIsLoading, setMediaIsLoading] = useState(true);
    const config = getConfig();

    const [toggler, setToggler] = useState(false);

    const getBoughtItems = async () => {
        await axiosInstance
            .get(`/user/orders?limit=8&page=0`, config)
            .then((res) => {
                setData(res?.data?.result?.orders);
                setIsLoading(false)
                console.log(res);
            });
    };

    const getUnlockables = async (asset) => {
        await axiosInstance
            .get(`/asset/private/view/${asset.id}`, config)
            .then((res) => {
                setMedia(res?.data?.result);
                setMediaIsLoading(false)
            });
    };

    const openLightBox = (curIdx) => {
        setCurSlide(curIdx);
        setLightBoxMedia([]);
        setLightBoxCaptions([]);
        let medias = [];
        let captions = [];
        media.forEach((m) => {

            let component;
            if(m.mediaType === 'image') {
                component =
                    <div className={'lightBoxWrapper'}>
                        <img src={m.mediaUrl} className={'img-fluid'}/>
                        <p>{m.description}</p>
                    </div>
            }else if(m.mediaType === 'video'){
                component = <div className={'lightBoxWrapper'}>
                    <video poster={m.coverImage} controlsList="nodownload" className={'img-fluid'} loop="true"
                           muted  controls playsInline>
                        <source src={m.mediaUrl}/></video>
                    <p>{m.description}</p>
                </div>
            }
            else if(m.mediaType === 'audio'){
                component = <div className={'lightBoxWrapper'}>
                    <video poster={m.coverImage} controlsList="nodownload" className={'img-fluid'} loop="true"
                           muted  controls playsInline>
                        <source src={m.mediaUrl}/></video>
                    <p>{m.description}</p>
                </div>
            }

            medias.push(component);
            captions.push(m.description)
        });
        setLightBoxMedia(medias);
        setLightBoxCaptions(captions);
        setToggler(!toggler);
    }

    useEffect(() => {
        getBoughtItems();
    }, []);

    useEffect(() => {
        if (data && data.length > 0) {
            console.log('Setting current item');
            console.log(data);
            setCurrentItem(data[0].orderItems[0].asset);
        }
    }, [data]);

    useEffect(() => {
        console.log("Current Item" + curItem);
        if (curItem) {
            setMediaIsLoading(true);
            getUnlockables(curItem)
        }

    }, [curItem])

    useEffect(() => {
        if(media){
            let mediasC = {};
            media.forEach((m) => {
                if (!(m.mediaType in mediasC)){
                    mediasC[m.mediaType] = [];
                }
                mediasC[m.mediaType].push(m);
            })
            console.log(mediasC);
            setMediaCategories(mediasC);
        }
    }, [media])


    return (
        <>
            {isLoading && (
                <div className={styles.loaderContent}>
                    <CircularProgress style={{color: "#FFF"}}/>
                </div>
            )}

            <Container>
                <Row>
                    <Col className={'m-0 p-0'} md={2} xs={12} style={{
                        backgroundColor: '#1f152e'
                    }}>
                        <div className={'d-block d-md-none mt-3'}>
                            <select className={'form-control'} onChange={(e) => {
                                setCurrentItem(JSON.parse(e.target.value));
                            }}>
                                {data?.length > 0 && (
                                    data?.map((x, index) => (
                                        x.orderItems.map((oi, oiIdx) => (
                                            <option value={JSON.stringify(oi.asset)}>
                                                {oi.asset.name}
                                            </option>
                                        ))
                                    ))
                                )}
                            </select>
                        </div>
                        <div className={'d-none d-md-block'}>
                            {data?.length > 0 ? (
                            data?.map((x, index) => (
                                x.orderItems.map((oi, oiIdx) => (
                                    <div onClick={() => {
                                        setCurrentItem(oi.asset);
                                    }} style={{
                                        color: '#FFF',
                                        cursor: "pointer"
                                    }}>
                                        <p style={{
                                            backgroundColor: curItem && curItem.id === oi.asset.id ? '#3E2F5F' : '#1f152e',
                                            color: curItem && curItem === oi.asset.id ? '#FFF' : '#7B7B7B',
                                            padding: '.5rem'
                                        }}>{oi.asset.name}</p>
                                    </div>
                                ))
                            ))
                        ) : (

                            !isLoading && <div className={`noRecordsFound ${styles.noRecordsFound}`}>
                                <span>No records found.</span>
                            </div>
                        )}
                        </div>
                    </Col>
                    <Col md={10} style={{minHeight: '500px', backgroundColor: '#3E2F5F'}}>

                            {mediaIsLoading && (
                                <Row className={'m-4'}>
                                    <Col className={'text-center'}>
                                        <div className={styles.loaderContent}>
                                            <CircularProgress style={{color: "#FFF"}}/>
                                        </div>
                                    </Col>
                                </Row>
                            )}

                            {!mediaIsLoading && mediaCategories && Object.keys(mediaCategories).map((category, i) => {

                                return <Row className={'m-4'}>
                                        <h1 style={{
                                            color: '#FFF',
                                            textTransform: 'capitalize'
                                        }}>{category}s</h1>
                                        <Row>
                                            {mediaCategories[category].map((m, j) => {
                                                return <Col md={4} xs={6} className={'p-1 mediaWrapper'} onClick={() => openLightBox(i+j+1)}>
                                                    <img src={m.coverImage} className={'img-fluid'} />
                                                </Col>
                                            })}
                                        </Row>
                                    </Row>

                            })}
                            <FsLightbox
                                toggler={toggler}
                                sources={lightBoxMedia}
                                captions={lightBoxCaptions}
                                slide={curSlide}
                            />
                    </Col>
                </Row>
            </Container>
        </>
    )
}
